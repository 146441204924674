<template>
  <div 
    :class="{
      'h-full overflow-y-auto bg-gray-lightest': !$route.meta.isCharter,
      'w-full': $route.meta.isCharter,
    }"
  >

    <router-view 
      @on-create="openLayModal()" 
      @on-archive="openArchiveModal" 
      @on-edit="openLayModal"
      @load-more="loadMore"
      :activeLoad="activeLoadMore"
      :countLayTime="countLayTime"
    />
  
    <modal-slots :showMod="isArchiveModalShow" @close="isArchiveModalShow = false">
      <template v-slot:title>
        <h2 class="fz18 fw500">Archive the LayTime</h2>
      </template>

      <p class="p-x-6 p-t-4 p-b-8">Are you sure you want to archive this LayTime?</p>

      <template v-slot:bottomFirstButton>
        <button class="blue-button-text fz14" @click="isArchiveModalShow = false">No</button>
      </template>
      <template v-slot:bottomSecondButton>
        <button class="m-l-8 p-x-16 blue-button fz14 fw500" @click="onArchive">Yes</button>
      </template>
    </modal-slots>

    <modal-slots :showMod="isCreateModalShow" @close="isCreateModalShow = false" :isPointerBg="false">
      <template v-slot:title>
        <h2 class="fz18 fw500">{{isLayEdit ? 'Edit' : 'Create'}} LayTime</h2>
      </template>

      <lay-modal-form @openModalPort="isShowModal=true" v-if="createLayData" ref="lay_modal" @on-validated="onSave" :data="createLayData" :isLayEdit="isLayEdit" />

      <template v-slot:bottomSecondButton>
        <button class="m-l-8 p-x-16 blue-button fz14 fw500" v-on:click="onCreateLay">{{isLayEdit ? 'Edit' : 'Create'}}</button>
      </template>
    </modal-slots>
    <add-modal
        :no-redirect="true"
        :isShowModal="isShowModal"
        @on-close="isShowModal = false"
        @added="reloadPorts"
    ></add-modal>

  </div>
</template>

<script>
import layModalForm from '@/components/lay/layModalForm'
import modalSlots from '@/components/modal/modal-slots'
import addModal from '@/components/ports/list/add'

import {mapActions, mapState, mapMutations} from 'vuex';

export default {
  components: {
    addModal,
    modalSlots,
    layModalForm
  },
  data() {
    return {
      activeLoadMore: false,
      isShowModal: false,
      isCreateModalShow: false,
      isArchiveModalShow: false,
      isLayEdit: false,
      archivedLay: null,
      rootLayData: {
        dispatch_formula: '1:2',
        contract: +this.$route.params.id,
        is_archive: false,
        cp_date: '',
        currency: 'USD',
        demurrage_rate: null,
        dispatch_rate: null,
        cargo: '',
        owner: {company_name: 'Speed Logistics Marine LTD', id: 120},
        lay_ports: [],
        time_allowed: 0,
        demurrage: 0
      },
      createLayData: null,
      idVessel: 0,
      loading_ports: [],
      discharging_ports: [],
      offset: 0,
      countLayTime: 0,
    }
  },
  computed: {
    ...mapState({
      lays: state => state.lay.lays,
      activeLay: state => state.lay.activeLay,
      contract: state => state.contract.activeContract,
    }),
    getCharterer() {
      return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    }
  },
  watch: {
    lays:function () {

      if(this.lays && this.lays.length !== 0) {
        if(this.idVessel !== this.$route.params.id) {
          if(this.lays && this.lays.length){
            this.setActiveLay(this.lays[0])
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['axiosCreateLay', 'axiosChangeLay', 'axiosGetLays', 'axiosOnArchive', 'axiosOpenContractById', 'axiosGetLaysByFilter']),
    ...mapMutations(['setActiveLay', 'hideLoad', 'showLoad']),

    reloadPorts() {
      this.$refs.lay_modal.reloadPorts()
    },
    async openLayModal(lay) {
      this.createLayData = null
      await this.$nextTick()
      
      if(lay){
        this.isLayEdit = true
        this.createLayData = JSON.parse(JSON.stringify(lay))
        this.createLayData.contract = this.createLayData.contract.id;
        this.createLayData.dispatch_formula = this.createLayData.dispatch_formula ? this.createLayData.dispatch_formula : '1:2'
      }else{
        this.isLayEdit = false
        // this.rootLayData
        this.createLayData = JSON.parse(JSON.stringify(this.rootLayData))
      }
      this.isCreateModalShow = true
    },
    onCreateLay() {
      this.$refs.lay_modal.onValidate()
    },
    openArchiveModal(lay) {
      this.archivedLay = lay
      this.isArchiveModalShow = true
    },
    getTimeAllowed() {
      let a_time = 0
      this.createLayData.lay_ports.forEach(el => {
        if(this.createLayData.additional_info.reversible_between && this.createLayData.additional_info.reversible_between === 'Loading ports') {
          if(el.loading_port){
            a_time += el.loading_quantity / el.loading_rate
          }
        } else if(this.createLayData.additional_info.reversible_between && this.createLayData.additional_info.reversible_between === 'Discharging ports') {
          if(el.discharging_port){
            a_time += el.discharging_quantity / el.discharging_rate
          }
        } else {
          if(el.discharging_port){
            a_time += el.discharging_quantity / el.discharging_rate
          }
          if(el.loading_port){
            a_time += el.loading_quantity / el.loading_rate
          }
          // console.log()
        }
      });
      this.createLayData.time_allowed = Math.round(a_time*10000000)/10000000
      let equal_days = 0
      if(this.createLayData.lay_times){
        this.createLayData.lay_times.forEach(row => {
          equal_days += row.equal_days
        })
      }
      let demTime = Math.abs((Math.round(a_time*10000000)/10000000) - (Math.round(equal_days * 10000000)/10000000))
      let dem_rate = a_time > equal_days ? Math.round((this.createLayData.demurrage_rate/2)*10000000)/10000000 : Math.round(this.createLayData.demurrage_rate*10000000)/10000000
      let result = demTime * dem_rate
      result = result ? Math.round(result*10000000)/10000000 : 0
      this.createLayData.demurrage = result
    },
    async onSave(isValid) {
      if(isValid) return false

      let savedLay = JSON.parse(JSON.stringify(this.createLayData))
      console.log('createLayData', this.createLayData)
      savedLay.lay_ports.forEach(el => {
        if(el.discharging_quantity == '') el.discharging_quantity = null
        if(el.discharging_rate == '') el.discharging_rate = null
        if(el.loading_quantity == '') el.loading_quantity = null
        if(el.loading_rate == '') el.loading_rate = null
        if(el.discharging_port){
          el.discharging_port = el.discharging_port.id
        }
        if(el.loading_port){
          el.loading_port = el.loading_port.id
        }
      })
      savedLay.owner = savedLay.owner ? savedLay.owner.id : null;


      if(this.isLayEdit){
        savedLay.id = this.$route.path.indexOf('lay/list') >= 0 ? savedLay.id : this.activeLay.id
        this.getTimeAllowed()
        let lay = await this.axiosChangeLay(savedLay)

        let index = this.lays.findIndex(el => {
          return el.id == this.activeLay.id
        })
        this.loading_ports = lay.additional_info?.loading_ports?.length ? lay.additional_info.loading_ports : []
        this.discharging_ports = lay.additional_info?.discharging_ports?.length ? lay.additional_info.discharging_ports : []

        if(!this.loading_ports.length) {
          let loading_ports = [];
          lay.lay_ports.forEach((item, index)=> {
            if(item.loading_port) {
              loading_ports.push({
                id_port: index,
                PORT_ID: item.id,
                loading_port_id: item.loading_port.id,
                arrived_load_date: '',
                loading_arrived_time: '',
                notice_tendered_loading_date: '',
                loading_notice_time: '',
                loading_commenced_date: '',
                loading_commenced_time: '',
                loading_completed_date: '',
                loading_completed_time: '',
                name: item.loading_port.name,
                country: item.loading_port.country,
              })
            }
          })
          this.loading_ports = loading_ports;
        } else if(this.loading_ports.length) {
          this.loading_ports.forEach((item, index)=> {
            let data = lay.lay_ports[item.id_port];
            let indexData = item.id_port;
            if(data) {
              // indexData !== item.loading_port_id
              if(data.loading_port && data.loading_port.id !== item.loading_port_id) {
                this.loading_ports[index] = {
                  id_port: indexData,
                  PORT_ID: data.id,
                  loading_port_id: data.loading_port.id,
                  arrived_load_date: '',
                  loading_arrived_time: '',
                  notice_tendered_loading_date: '',
                  loading_notice_time: '',
                  loading_commenced_date: '',
                  loading_commenced_time: '',
                  loading_completed_date: '',
                  loading_completed_time: '',
                  name: data.loading_port.name,
                  country: data.loading_port.country,
                }
              } else {
                if(!item.PORT_ID || item.PORT_ID !== data.id) {
                  item.PORT_ID = data.id
                }
              }
            } else {
              this.loading_ports = this.loading_ports.filter(a=> a.id_port !== item.id_port)
            }
          })
        }
        this.loading_ports = JSON.parse(JSON.stringify(this.loading_ports))
        if(!this.discharging_ports.length) {
          let discharging_ports = [];
          lay.lay_ports.forEach((item, index)=> {
            if(item.discharging_port) {
              discharging_ports.push({
                id_port: index,
                PORT_ID: item.id,
                discharging_port_id: item.discharging_port.id,
                arrived_discharging_date: '',
                discharging_arrived_time: '',
                notice_tendered_date: '',
                discharging_notice_time: '',
                discharging_commenced_date: '',
                discharging_commenced_time: '',
                discharging_completed_date: '',
                discharging_completed_time: '',
                name: item.discharging_port.name,
                country: item.discharging_port.country,
              })
            }
          })
          this.discharging_ports = discharging_ports;
        } else if(this.discharging_ports.length) {
          this.discharging_ports.forEach((item, index)=> {
            let data = lay.lay_ports[item.id_port];
            let indexData = item.id_port;
            if(data) {
              // || indexData !== item.id_port
              if(data.discharging_port && data.discharging_port.id !== item.discharging_port_id) {
                this.discharging_ports[index] = {
                  id_port: indexData,
                  PORT_ID: data.id,
                  discharging_port_id: data.discharging_port.id,
                  arrived_discharging_date: '',
                  discharging_arrived_time: '',
                  notice_tendered_date: '',
                  discharging_notice_time: '',
                  discharging_commenced_date: '',
                  discharging_commenced_time: '',
                  discharging_completed_date: '',
                  discharging_completed_time: '',
                  name: data.discharging_port.name,
                  country: data.discharging_port.country,
                }
              } else {
                if(!item.PORT_ID || item.PORT_ID !== data.id) {
                  item.PORT_ID = data.id
                }
              }
            } else {
              this.discharging_ports = this.discharging_ports.filter(a=> a.id_port !== item.id_port)
            }
          })
        }
        this.discharging_ports = JSON.parse(JSON.stringify(this.discharging_ports))
        lay.lay_ports.forEach((item, index)=> {
          let data = this.loading_ports.find(a=>{return a.id_port === index})
          if(!data && item.loading_port) {
            this.loading_ports.push({
              id_port: index,
              PORT_ID: item.id,
              loading_port_id: item.loading_port.id,
              arrived_load_date: '',
              loading_arrived_time: '',
              notice_tendered_loading_date: '',
              loading_notice_time: '',
              loading_commenced_date: '',
              loading_commenced_time: '',
              loading_completed_date: '',
              loading_completed_time: '',
              name: item.loading_port.name,
              country: item.loading_port.country,
            })
          } if (data && !item.loading_port) {
            this.loading_ports = this.loading_ports.filter(a=> a.id_port !== index)
          }
          data = this.discharging_ports.find(a=>{return a.id_port === index})
          if(!data && item.discharging_port) {
            this.discharging_ports.push({
              id_port: index,
              PORT_ID: item.id,
              discharging_port_id: item.discharging_port.id,
              arrived_discharging_date: '',
              discharging_arrived_time: '',
              notice_tendered_date: '',
              discharging_notice_time: '',
              discharging_commenced_date: '',
              discharging_commenced_time: '',
              discharging_completed_date: '',
              discharging_completed_time: '',
              name: item.discharging_port.name,
              country: item.discharging_port.country,
            })
          } if (data && !item.discharging_port) {
            this.discharging_ports = this.discharging_ports.filter(a=> a.id_port !== index)
          }
        })
        this.loading_ports.sort((a, b) => a.id_port > b.id_port ? 1 : -1);
        this.discharging_ports.sort((a, b) => a.id_port > b.id_port ? 1 : -1);
        lay['additional_info']['loading_ports'] = this.loading_ports;
        lay['additional_info']['discharging_ports'] = this.discharging_ports;
        lay = JSON.parse(JSON.stringify(lay))

        this.getTimeAllowed()

        lay.lay_ports.forEach(el => {
          if(el.discharging_quantity == '') el.discharging_quantity = null
          if(el.discharging_rate == '') el.discharging_rate = null
          if(el.loading_quantity == '') el.loading_quantity = null
          if(el.loading_rate == '') el.loading_rate = null
          if(el.discharging_port){
            el.discharging_port = el.discharging_port.id
          }
          if(el.loading_port){
            el.loading_port = el.loading_port.id
          }
        })
        lay.owner = lay.owner ? lay.owner.id : null;
        lay.contract = lay.contract ? lay.contract.id : null;


        await this.axiosChangeLay(lay)

        index = this.lays.findIndex(el => {
          return el.id == this.activeLay.id
        })
        this.setActiveLay(this.lays[index])
      }else{
        this.getTimeAllowed()
        let response = await this.axiosCreateLay(savedLay)
        if(this.lays)
          this.lays.push(response)
        else
          await this.loadLayTime()
        // await this.axiosGetLays(this.$route.params.id)
        this.setActiveLay(this.lays[this.lays.length - 1])
      }

      this.isCreateModalShow = false
    },
    async onArchive() {
      await this.axiosOnArchive({id: this.archivedLay.id, is_archive: true})
      this.isArchiveModalShow = false
      
      if(this.lays?.length && (this.archivedLay?.id == this.activeLay?.id))
        this.setActiveLay(this.lays[0])
    },
    async loadLayTime() {
      this.showLoad()
      let form = {
        id: this.$route.params.id,
        params: {limit: 50}
      }
      this.countLayTime = 0
      form['params']['offset'] = this.offset
      if(this.$route.meta.isCharter) {
        form['params']['cp_date'] = this.getCharterer?.cp_date
      }
      this.countLayTime = await this.axiosGetLaysByFilter(form)
      this.hideLoad()
    },
    async loadMore() {
      this.activeLoadMore = true
      this.offset+=50
      let form = {
        id: this.$route.params.id,
        params: {limit: 50}
      }
      form['params']['offset'] = this.offset
      if(this.$route.meta.isCharter) {
        form['params']['cp_date'] = this.getCharterer?.cp_date
      }
      form['loadMore'] = true
      this.countLayTime = await this.axiosGetLaysByFilter(form)

      this.activeLoadMore = false
    },
  },

  async beforeMount() {
    this.idVessel = this.$route.params.id

    //this.showLoad()
    //await this.axiosOpenContractById(this.$route.params.id)
    // await this.axiosGetLays(this.$route.params.id)
    await this.loadLayTime()
    
    if(this.lays && this.lays.length){
      if(!this.activeLay)
        this.setActiveLay(this.lays[0])
      else{
        let index = this.lays.findIndex(el => {
          return el.id == this.activeLay.id
        })
        if(index >= 0) {
          this.setActiveLay(this.lays[index])
        } else {
          this.setActiveLay(this.lays[0])
        }
      }
    }
  }
}
</script>

<style lang="scss">
.lay_sidebar{
  width: 204px;
}
</style>