import axios from 'axios'

export default {
    actions: {
        axiosLoginOnPassword(ctx, data) {
            return axios.post(process.env.VUE_APP_URL + "/login/login/password", {
                phone_number: data.phone_number,
                password: data.password,
            },
            ).then(response => {
                ctx.commit("updateLoginData", response, {root: true});
                ctx.commit("setToken", response, {root: true});
                ctx.commit("setPermissionForUser", response.data.permissions, {root: true});
                localStorage.setItem("per", JSON.stringify(response.data.permissions))
                return true
            }).catch(() => {
                ctx.commit("setAlert", { show: true, text: "Email or password not found" }, { root: true });
                return false
            });
        },
        axiosChangePassword(ctx, data) {
            return axios.patch(process.env.VUE_APP_URL + "/user/" + data.id, {
                password: data.password,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                  },
            }
            ).then(() => {
                return true
            }).catch(() => {
                return false
            });
        },
        axiosLogin(ctx, data) {
            return axios.post(process.env.VUE_APP_URL + "/login/code", {
                phone_number: data.phone,
                // password: "admin",
            },
            ).then(response => {
                // console.log("RESPONSE LOGIN")
                // console.log(response);
                ctx.commit("updateLoginStatus", response);
            }).catch(() => {
                if (ctx.getters.getToken > 20)
                    ctx.commit("setAlert", { show: true, text: "Number not found" }, { root: true });
            });
        },
        axiosCheckUser(ctx, phone) {
            return axios.post(process.env.VUE_APP_URL + "/login/check", {
                phone_number: phone
            }).then(response => {
                console.log(response.data.is_valid)
                return response.data;
            }).catch(() => {
                return false;
            })
        }

    },
    mutations: {
        updateLoginStatus(state, data) {
            state.statusLogin = data;
        },
    },
    state: {
        statusLogin: {},
    },
    getters: {
        getLoginStatus(state) {
            return state.statusLogin;
        },
    },
}
