import axios from 'axios';

export default {
  actions: {
    axiosGetAvailableDateTrialBalance(ctx) {
      return axios.get(process.env.VUE_APP_URL+'/payment/account/balance/years', {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(res=> {
        ctx.state.dateTrialBalance = res.data;
      })
    },
    async axiosGetTrials(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/payment/account/balance/?is_archive=0',
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.commit('setTrials', response.data)
      }).catch(error => {
        console.error('axiosGetTrials', error);
      })
    },
    async axiosGetDateForAllPeriodTrialBalance(ctx) {
      await axios.get(process.env.VUE_APP_URL+'',
          {
            headers: {
              'Authorization': 'token ' + ctx.getters.getToken,
            }
          })
    },
    async axiosGetTrialsByTime(ctx, time) {
      let timeStr = 'paid_date_after='+time[0]+'&paid_date_before='+ time[1]
      await axios.get(process.env.VUE_APP_URL+'/payment/account/balance/?is_archive=0&' + timeStr,
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.commit('setTrials', response.data)
      }).catch(error => {
        console.error('axiosGetTrials', error);
      })
    },
    async axiosGetTrialsArchive(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/payment/account/balance/?is_archive=1',
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.commit('setTrialsArchive', response.data)
      }).catch(error => {
        console.error('axiosGetTrials', error);
      })
    },
    /* async axiosGetTrialsByPeriod(ctx, date) {
      await axios.get(process.env.VUE_APP_URL+'/payment/account/balance/?execute_date_after=2021-04-01 10:00:00&execute_date_before=2021-04-01 10:00:00',
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.commit('setTrials', response.data.results)
      }).catch(error => {
        console.error('axiosGetTrials', error);
      })
    }, */
    async axiosAddTrialAccount(ctx, account) {
      account = JSON.parse(JSON.stringify(account));
      if(account) {
        if(Array.isArray(account.accounts_field) == true && account.accounts_field.length == 0) {
          delete account.accounts_field
        }
      }
      return await axios.post(process.env.VUE_APP_URL+'/payment/account/', account,
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        return true
      }).catch(() => {
        return false
      })
    },
    async axiosEditTrialAccount(ctx, {account, id}) {
      return await axios.patch(process.env.VUE_APP_URL+'/payment/account/' + id, account,
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        return true
      }).catch(() => {
        return false
      })
    },
    async axiosTrialAccountToArchive(ctx, {ids, bool}) {
      return await axios.post(process.env.VUE_APP_URL+'/payment/account/archive/', {ids, is_archived: bool},
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        // console.log('axiosTrialAccountToArchive', response);
      }).catch(() => {
        // console.log('axiosTrialAccountToArchive', error);
      })
    },
    async axiosAddFS(ctx, fs) {
      return await axios.post(process.env.VUE_APP_URL+'/fs/', fs,
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        return true
      }).catch(() => {
        return false
      })
    },
    async axiosEditFS(ctx, obj) {
      return await axios.put(process.env.VUE_APP_URL+'/fs/' + obj.id, obj,
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        return true
      }).catch(() => {
        return false
      })
    },
    async axiosGetsetFinancialStatementlist(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/fs/?is_archive=false',
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.commit('setFinancialStatement', response.data.results)
      }).catch(error => {
        console.error('axiosGetsetFinancialStatementlist', error);
      })
    },
    async axiosGetsetFinancialStatementArchivelist(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/fs/?is_archive=true',
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.commit('setFinancialStatementArchive', response.data.results)
      }).catch(error => {
        console.error('axiosGetsetFinancialStatementlist', error);
      })
    }
  },
  mutations: {
    setTrials(state, trials) {
      state.trials = []
      state.trialsStatment = []
      state.trialsProfit = []
      
      trials.forEach(item => {
        state.trials.push(item)
        item.account_id < 6101 ? state.trialsStatment.push(item) : state.trialsProfit.push(item);
      })

    },
    setTrialsArchive(state, trials) {
      state.trialsArchive = []
      
      trials.forEach(item => {
        state.trialsArchive.push(item)
      })
    },
    setFinancialStatement(state, accounts) {
      state.financialStatement = []

      accounts.forEach(item => {
        state.financialStatement.push(item)
      })
    },
    setFinancialStatementArchive(state, accounts) {
      state.financialStatementArchive = []

      accounts.forEach(item => {
        state.financialStatementArchive.push(item)
      })
    }
  },
  state: {
    trials: [], // all trials account (not archive)
    trialsArchive: [],
    trialsStatment: [], // id < 6000
    trialsProfit: [], // id > 6000
    financialStatement: [],
    financialStatementArchive: [],
    dateTrialBalance: null,
    fsType: [
      {
        name: 'Non-current assets',
        type: 'non_current_assets'
      },
      {
        name: 'Current assets',
        type: 'current_assets'
      },
      {
        name: 'Equity attributable to owners of the company',
        type: 'equity_attributable_to_owners_of_the_parent'
      },
      {
        name: 'Retained earnings',
        type: 'retained_earnings'
      },
      {
        name: 'Non-current liabilities',
        type: 'non_current_liabilities'
      },
      {
        name: 'Current liabilities',
        type: 'current_liabilities'
      },
      {
        name: 'Revenue from contracts with customers',
        type: 'revenue_from_contracts_with_customers'
      },
      {
        name: 'Operating expenses',
        type: 'operating_expenses'
      },
      {
        name: 'Administrative expenses',
        type: 'administrative_expenses'
      },
      {
        name: 'Income tax',
        type: 'income_tax'
      },

      // {
      //   name: 'No type',
      //   type: 'no_type'
      // },





      
    ],
    fsSubtype: [
      {
        name: 'General',
        type: 'general'
      },
      {
        name: 'Operating profit',
        type: 'operating_profit'
      },
      {
        name: 'Profit before tax',
        type: 'profit_before_tax'
      },
    ]
  },
  getters: {
  }
}