<template>
    <ModalSlots class="wrap-modal-change" v-if="showModal" @apply="acept" :show-mod="true" @close="closeModal" :textForFirstButton="'Cancel'" :textForSecondButton="'Save'">
        <template v-slot:title>
            <p class="fz18 fw500">Change password</p>
        </template>
        <div class="block-modal">
            <p class="fz16 text-dark">Enter new password</p>
            <input :class="{'error': errorPassword}" type="text" class="mt4" v-model="password">
        </div>
     
    </ModalSlots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
import { mapActions } from "vuex";
export default {
    props: {
        id:{
            type:  Number,
        },
        showModal: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            password: '',
            errorPassword: false,
        }
    },
    components: {ModalSlots},

    methods: {
        ...mapActions(['axiosChangePassword']),
        async closeModal() {
            this.$emit('close')
        },
        async acept() {
            this.errorPassword  = !this.password.length
            if(this.errorPassword) return

            let res = await this.axiosChangePassword({id: this.id, password: this.password})

            if(res) {
                this.$emit('close')
            }
        }

    }
}
</script>

<style scoped lang="scss">
.wrap-modal-change {
    width: 100vw !important;
    justify-content: center !important;
}
.block-modal {
    padding: 30px;
}
</style>