<template>
  <div class="p-t-4 w500">
    <div class="p-x-6 p-b-5">
      <counterparties-drop :class="{ 'block-input_error': $v.data.cp_date.$error }" :list="getCpDates"
        :activeItem="getCpDateActive" :removeSelectItemBeforeClick="false" @select="selectedDate"
        :isDisabled="this.$route.params.subId ? true : false" title="CP Date" />
      <div class="text-red fz12" v-if="!$v.data.cp_date.required && $v.data.cp_date.$dirty">Field is required</div>
      <label class="block-input">
        <p class="block-input_title text-gray">Reversible Between</p>
        <counterparties-drop v-if="data.additional_info && data.additional_info.reversible_between"
          :key="'23s' + data.additional_info.reversible_between" class="m-t-0" ref="dropCurrency" :list="[
            { name: 'Non reversible', id: 'Non reversible' },
            { name: 'Loading ports', id: 'Loading ports' },
            { name: 'Discharging ports', id: 'Discharging ports' },
            { name: 'Loading/Discharging ports', id: 'Loading/Discharging ports' },
          ]" :dontSelect="true" :removeSelectItemBeforeClick="false"
          :activeItem="{ name: data.additional_info.reversible_between, id: data.additional_info.reversible_between }"
          @select="(item) => data.additional_info.reversible_between = item.name" title=""></counterparties-drop>
      </label>
      <label class="block-input">
        <!--        <p class="block-input_title text-gray">Owner</p>-->
        <async-select class="caps-name" v-model="data.owner" :allowEmpty="true" :closeOnSelect="true" label="Owner"
          url="/client/dropdown" :placeholder="''" :params="{ ordering: 'company_name', is_archived: false }"
          watchKey="company_name"></async-select>
        <!--        <input class="block-input_content" type="text" maxlength="255" disabled v-model="owner">-->
      </label>
      <label class="block-input">
        <p class="block-input_title text-gray">Charterer</p>
        <input class="block-input_content" type="text" maxlength="255" disabled v-model="charterer">
      </label>
      <label class="block-input">
        <p class="block-input_title text-gray">Currency</p>
        <counterparties-drop class="m-t-0" ref="dropCurrency" :list="valutaList" :dontSelect="true"
          :removeSelectItemBeforeClick="false" :activeItem="{ name: data.currency, id: data.currency }"
          @select="(item) => data.currency = item.name" title=""></counterparties-drop>
      </label>
      <label class="block-input" :class="{ 'block-input_error': $v.data.demurrage_rate.$error }">
        <p class="block-input_title text-gray">Demurrage Rate</p>
        <input class="block-input_content" type="text" maxlength="255" v-model.number="$v.data.demurrage_rate.$model"
          @blur="onBlurDemurrage" @input="$v.data.demurrage_rate.$model = checkNum($v.data.demurrage_rate.$model)"
          @focusout="$v.data.demurrage_rate.$model = focusoutCheckNum($v.data.demurrage_rate.$model)">
      </label>
      <div class="text-red fz12" v-if="!$v.data.demurrage_rate.required && $v.data.demurrage_rate.$dirty">Field is
        required</div>
      <!--      <div class="text-red fz12" v-if="!$v.data.demurrage_rate.decimal && $v.data.demurrage_rate.$dirty">Field must be integer</div>-->


      <div class="block-two">
        <div class="wrap-formula-dispatch">
          <p class="block-input_title text-gray">Dispatch Rate</p>
          <div class="formula-dispath">
            <button :class="{ 'active-selected-button': activeFormulaDispatch == '1:1' }"
              v-on:click="() => { activeFormulaDispatch = '1:1'; data.dispatch_formula = activeFormulaDispatch; onBlurDemurrage() }">
              1:1
            </button>
            <button :class="{ 'active-selected-button': activeFormulaDispatch == '1:2' }"
              v-on:click="() => { activeFormulaDispatch = '1:2'; data.dispatch_formula = activeFormulaDispatch; onBlurDemurrage() }">1:2</button>
            <button :class="{ 'active-selected-button': activeFormulaDispatch == 'custom' }"
              v-on:click="() => { activeFormulaDispatch = 'custom'; data.dispatch_formula = activeFormulaDispatch; onBlurDemurrage() }">Custom</button>
          </div>
        </div>

        <div class="dispatch-block">
          <label class="block-input" :class="{ 'block-input_error': $v.data.dispatch_rate.$error }">
            <p class="block-input_title text-gray">Dispatch Rate</p>
            <input :disabled="activeFormulaDispatch == '1:1' || activeFormulaDispatch == '1:2'" class="block-input_content" type="text" maxlength="255" v-model.number="$v.data.dispatch_rate.$model"
              @blur="onBlurDispatch" @input="$v.data.dispatch_rate.$model = checkNum($v.data.dispatch_rate.$model)"
              @focusout="$v.data.dispatch_rate.$model = focusoutCheckNum($v.data.dispatch_rate.$model)">
          </label>
          <div class="text-red fz12" v-if="!$v.data.dispatch_rate.decimal && $v.data.dispatch_rate.$dirty">Field must be
            integer</div>
        </div>
      </div>

      <label class="block-input">
        <p class="block-input_title text-gray">Cargo</p>
        <textarea class="block-input_content" rows="3" maxlength="2000" v-model="data.cargo"></textarea>
      </label>
    </div>

    <div class="p-x-6 p-b-0 border-t border-card-border" v-for="(v, i) in $v.data.lay_ports.$each.$iter" :key="i">
      <p class="fz16 fw500 text-dark p-t-3 position-relative d-flex align-items-center">Loading/Discharging Port
        #{{ 1 + Number(i) }}
        <button class="flex items-center fz13 fw500 text-blue-dark position-absolute r0" @click="onDelete(i)">
          <svg class="m-r-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"
              fill="#094172" />
          </svg>
          Delete
        </button>
      </p>

      <h2 class="p-t-3 fz14 fw500 text-align-left position-relative d-flex align-items-center w100Proc justify-between">
        Loading
        <!--        (!data.lay_ports[i].loading_port || (data.lay_ports[i].loading_port && !data.lay_ports[i].loading_port.id))-->
        <switcher class="position-absolute r0" :id="'loading' + i" v-model="data.lay_ports[i].loading_show"
          @input="clearPort(i, 'loading')"></switcher>
      </h2>

      <div class="w100Proc box-border position-relative" v-show="data.lay_ports[i].loading_show">
        <div class="blue-button-text button-new-port fz13" v-on:click="openModalPort">Create new port</div>
        <async-select-port :key="keyRenderPorts" class="m-t-3" v-model="v.loading_port.$model" :allowEmpty="true"
          :id="'loading_port' + i" :label="'Port of Loading #' + (+i + 1)"
          :isErrorRequired="!v.loading_port.required && v.loading_port.$dirty" />
      </div>

      <label class="block-input relative" :class="{ 'block-input_error': v.loading_quantity.$error }"
        v-show="data.lay_ports[i].loading_show">
        <p class="block-input_title text-gray">Quantity of Loading Cargo</p>
        <input class="block-input_content p-r-6" type="text" maxlength="255" v-model.number="v.loading_quantity.$model"
          @input="v.loading_quantity.$model = checkNum(v.loading_quantity.$model, 3)"
          @focusout="v.loading_quantity.$model = focusoutCheckNum(v.loading_quantity.$model, 3)">
        <div class="absolute right-2 bottom-2.5 fz14">mt</div>
      </label>
      <div class="text-red fz12" v-if="!v.loading_quantity.required && v.loading_quantity.$dirty"
        v-show="data.lay_ports[i].loading_show">Field is required</div>
      <div class="text-red fz12" v-if="!v.loading_quantity.decimal && v.loading_quantity.$dirty"
        v-show="data.lay_ports[i].loading_show">Field must be integer</div>

      <div class="block-two-inputs" v-show="data.lay_ports[i].loading_show">
        <div>
          <label class="block-input relative">
            <p class="block-input_title text-gray">Calculation type</p>
            <div class="block-choose-type">
              <button class="fz14 text-dark" :class="{ 'active': !data.lay_ports[i].loading_is_day }"
                v-on:click="data.lay_ports[i].loading_is_day = !data.lay_ports[i].loading_is_day">
                Rate
              </button>
              <button class="fz14 text-dark" :class="{ 'active': data.lay_ports[i].loading_is_day }"
                v-on:click="data.lay_ports[i].loading_is_day = !data.lay_ports[i].loading_is_day">
                Days
              </button>
            </div>
          </label>
        </div>
        <div>
          <label class="block-input relative" :class="{ 'block-input_error': v.loading_rate.$error }">
            <p class="block-input_title text-gray">{{ !data.lay_ports[i].loading_is_day ? 'Rate of Loading' : 'Days' }}
            </p>
            <input class="block-input_content p-r-6" type="text" maxlength="255" v-model.number="v.loading_rate.$model"
              @input="v.loading_rate.$model = checkNum(v.loading_rate.$model, 4)"
              @focusout="v.loading_rate.$model = focusoutCheckNum(v.loading_rate.$model, 4)">
            <div class="absolute right-2 bottom-2.5 fz14" v-if="!data.lay_ports[i].loading_is_day">mt</div>
          </label>
          <div class="text-red fz12" v-if="!v.loading_rate.required && v.loading_rate.$dirty">Field is required</div>
          <div class="text-red fz12" v-if="!v.loading_rate.decimal && v.loading_rate.$dirty">Field must be integer</div>
        </div>
      </div>

      <default-select v-show="data.lay_ports[i].loading_show" class="m-t-3" v-model="data.lay_ports[i].loading_terms"
        :options="layTerms" :id="'loading_terms' + i" :searchable="false" :trackBy="null" :openDirection="null"
        label="Terms of Loading" :allowEmpty="true" :closeOnSelect="true" />


      <h2 class="p-t-3 fz14 fw500 text-align-left position-relative d-flex align-items-center w100Proc justify-between">
        Discharging
        <!--        (!data.lay_ports[i].discharging_port || (data.lay_ports[i].discharging_port && !data.lay_ports[i].discharging_port.id))-->
        <switcher class="position-absolute r0" :id="'discharging' + i" v-model="data.lay_ports[i].discharging_show"
          @input="clearPort(i, 'discharging')"></switcher>
      </h2>
      <div class="w100Proc box-border position-relative" v-show="data.lay_ports[i].discharging_show">
        <div class="blue-button-text button-new-port fz13" v-on:click="openModalPort">Create new port</div>
        <async-select-port :key="keyRenderPorts" class="m-t-3" v-model="v.discharging_port.$model" :allowEmpty="true"
          :id="'discharging_port' + i" :label="'Port of Discharging #' + (+i + 1)"
          :isErrorRequired="!v.discharging_port.required && v.discharging_port.$dirty" />
      </div>

      <label class="block-input relative" :class="{ 'block-input_error': v.discharging_quantity.$error }"
        v-show="data.lay_ports[i].discharging_show">
        <p class="block-input_title text-gray">Quantity of Discharging Cargo</p>
        <input class="block-input_content p-r-6" type="text" maxlength="255"
          v-model.number="v.discharging_quantity.$model"
          @input="v.discharging_quantity.$model = checkNum(v.discharging_quantity.$model, 3)"
          @focusout="v.discharging_quantity.$model = focusoutCheckNum(v.discharging_quantity.$model, 3)">
        <div class="absolute right-2 bottom-2.5 fz14">mt</div>
      </label>
      <div class="text-red fz12" v-if="!v.discharging_quantity.required && v.discharging_quantity.$dirty"
        v-show="data.lay_ports[i].discharging_show">Field is required</div>
      <div class="text-red fz12" v-if="!v.discharging_quantity.decimal && v.discharging_quantity.$dirty"
        v-show="data.lay_ports[i].discharging_show">Field must be integer</div>
      <div class="block-two-inputs" v-show="data.lay_ports[i].discharging_show">
        <div>
          <label class="block-input relative">
            <p class="block-input_title text-gray">Calculation type</p>
            <div class="block-choose-type">
              <button class="fz14 text-dark" :class="{ 'active': !data.lay_ports[i].discharging_is_day }"
                v-on:click="data.lay_ports[i].discharging_is_day = !data.lay_ports[i].discharging_is_day">
                Rate
              </button>
              <button class="fz14 text-dark" :class="{ 'active': data.lay_ports[i].discharging_is_day }"
                v-on:click="data.lay_ports[i].discharging_is_day = !data.lay_ports[i].discharging_is_day">
                Days
              </button>
            </div>
          </label>
        </div>
        <div>
          <label class="block-input relative" :class="{ 'block-input_error': v.discharging_rate.$error }">
            <p class="block-input_title text-gray">{{ !data.lay_ports[i].discharging_is_day ? 'Rate of Discharging' :
              'Days' }}</p>
            <input class="block-input_content p-r-6" type="text" maxlength="255"
              v-model.number="v.discharging_rate.$model"
              @input="v.discharging_rate.$model = checkNum(v.discharging_rate.$model, 4)"
              @focusout="v.discharging_rate.$model = focusoutCheckNum(v.discharging_rate.$model, 4)">
            <div class="absolute right-2 bottom-2.5 fz14" v-if="!data.lay_ports[i].discharging_is_day">mt</div>
          </label>
          <div class="text-red fz12" v-if="!v.discharging_rate.required && v.discharging_rate.$dirty">Field is required
          </div>
          <div class="text-red fz12" v-if="!v.discharging_rate.decimal && v.discharging_rate.$dirty">Field must be
            integer</div>
        </div>
      </div>
      <default-select v-show="data.lay_ports[i].discharging_show" class="m-t-3"
        v-model="data.lay_ports[i].discharging_terms" :options="layTerms" :id="'discharging_terms' + i"
        :searchable="false" :trackBy="null" :openDirection="null" label="Terms of Discharging" :allowEmpty="true"
        :closeOnSelect="true" />

      <div class="flex items-center p-t-3">
      </div>
    </div>
    <div class="border-top p-x-6 box-border h56 d-flex align-items-center justify-center">
      <button class="flex items-center m-l-auto fz13 fw500 button-blue-border w100Proc h32" :class="{
        // 'text-blue-dark': !v.$invalid,
        // 'text-gray-text': v.$invalid
      }" @click="onAddPort(data.lay_ports ? $v.data.lay_ports.$each.$iter[data.lay_ports.length - 1] : null)">
        <svg class="m-r-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.3333 11.3333H12.6667V6.66662C12.6667 6.2987 12.368 6 11.9999 6C11.632 6 11.3333 6.2987 11.3333 6.66662V11.3333H6.66662C6.2987 11.3333 6 11.632 6 11.9999C6 12.368 6.2987 12.6667 6.66662 12.6667H11.3333V17.3333C11.3333 17.7013 11.632 18 11.9999 18C12.368 18 12.6667 17.7013 12.6667 17.3333V12.6667H17.3333C17.7013 12.6667 18 12.368 18 11.9999C18 11.632 17.7013 11.3333 17.3333 11.3333Z" />
        </svg>
        Add new Loading/Discharging Port
      </button>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, decimal, minValue, requiredIf, minLength } from 'vuelidate/lib/validators'

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'
import asyncSelectPort from '@/components/reuse/asyncSelectPort'
import defaultSelect from '@/components/reuse/defaultSelect'
import AsyncSelect from "@/components/reuse/asyncSelect";
import Switcher from "@/components/reuse/switcher";

export default {
  mixins: [validationMixin],
  props: {
    data: Object,
    isLayEdit: Boolean
  },
  components: {
    Switcher,
    AsyncSelect,
    counterpartiesDrop,
    asyncSelectPort,
    defaultSelect
  },
  data() {
    return {
      activeFormulaDispatch: '1:2',
      keyRenderPorts: -923123,
      layTerms: [
        'Shinc: sun & holidays incl',
        'Shex 1: sun & holidays excl',
        'Shex 2: sat 12 to sun 24 excl',
        'Shex 3: sat 12 to mon 8 excl',
        'Shex 4: sat & sun excl',
        'Shex 6: fri 24 to mon 8 excl',
        'Shex 7: fri 17 to mon 8 excl',
        'Shex 8: thu 12 to sun 8 excl',
        'Fhex 1: fri and holid excl',
        'Fhex 2: wed 17 to sat 8 excl',
        'Fhinc: fri and hold incl'
      ],
      owner: '',
      charterer: '',
    }
  },
  watch: {
  },
  validations: {
    data: {
      cp_date: {
        required
      },
      demurrage_rate: {
        required,
        decimal,
        minValue: minValue(0)
      },
      dispatch_rate: {
        decimal,
        minValue: minValue(0)
      },

      lay_ports: {
        // required,
        minLength: minLength(1),
        $each: {
          loading_port: {
            required: requiredIf(function (nestedModel) {
              //   return nestedModel.discharging_port ? false : true
              return nestedModel.loading_quantity || nestedModel.loading_rate ? true : false
            })
          },
          loading_quantity: {
            decimal,
            required: requiredIf(function (nestedModel) {
              return nestedModel.loading_port ? true : false
            })
          },
          loading_rate: {
            decimal,
            required: requiredIf(function (nestedModel) {
              return nestedModel.loading_port ? true : false
            })
          },
          discharging_port: {
            required: requiredIf(function (nestedModel) {
              // return nestedModel.loading_port ? false : true
              return nestedModel.discharging_quantity || nestedModel.discharging_rate ? true : false

            })
          },
          discharging_quantity: {
            decimal,
            required: requiredIf(function (nestedModel) {
              return nestedModel.discharging_port ? true : false
            })
          },
          discharging_rate: {
            decimal,
            required: requiredIf(function (nestedModel) {
              return nestedModel.discharging_port ? true : false
            })
          },
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getTimeAllowed']),
    ...mapState({
      valutaList: state => state.payment.valutaList,
      activeLay: state => state.lay.activeLay,
      activeContract: state => state.contract.activeContract,
    }),
    getCpDates() {
      return this.activeContract.cargo_contracts.filter(el => {
        return !Number.isInteger(el) && (el.contract_type == 'CARGO' || el.contract_type == 'VOYAGE_CHARTERER')
      }).map(el => {
        return {
          id: el.id,
          cp_date: el.cp_date,
          name: `${moment(el.cp_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}(${el.owner?.company_name})`
        }
      })
    },
    getCpDateActive() {
      return this.getCpDates.find(el => {
        if (this.$route.params.subId) {
          return el.id == this.$route.params.subId && el.cp_date == this.data.cp_date
        } else {
          return el.cp_date == this.data.cp_date
        }
      })
    },
    layTermsList() {
      return this.layTerms.map(el => {
        return {
          id: el,
          name: el
        }
      })
    }
  },
  methods: {
    ...mapActions(['axiosGetClientsByIdReturnResponse', 'axiosGetLastInvoiceByType']),
    getTotalEquals() {
      let summ = 0
      let rows = this.data.lay_times ? this.data.lay_times : []
      rows.forEach(row => {
        summ += row.equal_days
      })
      return summ
    },
    getDemTime() {
      return Math.abs((Math.round(this.getTimeAllowed * 100000000) / 100000000) - (Math.round(this.getTotalEquals() * 100000000) / 100000000))
    },
    getDemurrage() {
      let dem_rate = this.getTimeAllowed > this.getTotalEquals() ? this.data.demurrage_rate / 2 : this.data.demurrage_rate
      let result = this.getDemTime() * dem_rate
      result = Math.round(result * 10000000) / 10000000

      this.data.demurrage = result
      // if(this.getTotalEquals() < this.getTimeAllowed) {
      //   result = Math.trunc(result) + Math.floor(((result % 1)*100))/100
      // }
      // return result
    },
    clearPort(index, port) {
      this.data.lay_ports[index][port + '_port'] = null
      this.data.lay_ports[index][port + '_quantity'] = null
      this.data.lay_ports[index][port + '_is_day'] = false
      this.data.lay_ports[index][port + '_rate'] = null
      this.data.lay_ports[index][port + '_port'] = null
      this.data.lay_ports[index][port + '_terms'] = null
    },
    focusoutCheckNum(num, countAfterDot) {
      if (!countAfterDot) {
        countAfterDot = 2
      }
      console.log(num)
      if (num && ('' + num).length && num.indexOf(".") >= 0) {
        num = '' + num
        // if((''+num).indexOf(".")>=0) {
        let num2 = num.substring(num.indexOf(".") + 1, num.length);
        if (num && ('' + num).length !== 0 && num2.length <= (countAfterDot - 1)) {
          for (let i = num2.length; i < countAfterDot; i++) {
            num += '0'
          }
          return num
        }
        // } else {
        //   num+='.00'
        // }
      }
      return num
    },
    checkNum(num, countAfterDot) {
      if (!countAfterDot) {
        countAfterDot = 2
      }
      if (('' + num).indexOf(".") >= 0) {
        num = '' + num
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);

        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");
        console.log('num2', num2)
        if (num2.length > countAfterDot) {
          num2 = num2.substring(0, countAfterDot);
        }
        num = num1 + "." + num2;
      } else {
        num = ('' + num).replace(/[^0-9]/g, "")
      }

      return num ? num : null;
    },
    reloadPorts() {
      this.keyRenderPorts++;
    },
    openModalPort() {
      this.$emit('openModalPort')
    },
    onBlurDemurrage() {
      if (this.activeFormulaDispatch == 'custom') return;
      if (this.activeFormulaDispatch == '1:1' && parseFloat(this.data.demurrage_rate))
        this.data.dispatch_rate = this.data.demurrage_rate / 1
      if (this.activeFormulaDispatch == '1:2' && parseFloat(this.data.demurrage_rate))
        this.data.dispatch_rate = this.data.demurrage_rate / 2

      if (this.data.dispatch_rate && ('' + this.data.dispatch_rate).length !== 0) {
        this.data.dispatch_rate = this.focusoutCheckNum(this.data.dispatch_rate)
      }
    },
    onBlurDispatch() {
      if (this.activeFormulaDispatch == 'custom') return;
      if (this.activeFormulaDispatch == '1:1' && parseFloat(this.data.dispatch_rate))
        this.data.demurrage_rate = this.data.dispatch_rate * 1
      if (this.activeFormulaDispatch == '1:2' && parseFloat(this.data.dispatch_rate))
        this.data.demurrage_rate = this.data.dispatch_rate * 2
    },
    onAddLayPort() {
      this.data.lay_ports.push({
        discharging_show: true,
        loading_show: true,
        loading_quantity: null,
        loading_rate: null,
        loading_terms: null,
        loading_port: null,
        loading_is_day: false,
        discharging_quantity: null,
        discharging_rate: null,
        discharging_terms: null,
        discharging_port: null,
        discharging_is_day: false,
      })
    },
    onAddPort() {
      this.$v.$touch()
      // if(!v || !v.$invalid)
      this.onAddLayPort()
    },
    onDelete(index) {
      this.data.lay_ports.splice(index, 1)

      if (!this.data.lay_ports.length)
        this.onAddLayPort()
    },
    async selectedDate(item) {
      this.data.cp_date = item.cp_date
      let charterer = this.activeContract.cargo_contracts.find(el => {
        return el.id == item.id
      })
      if (charterer) {
        if (Number.isInteger(charterer.owner)) {
          charterer.owner = await this.axiosGetClientsByIdReturnResponse(charterer.owner)
        }

        this.charterer = charterer.owner ? charterer.owner.company_name : ''
        this.data['additional_info']['charterer'] = item.id
      } else {
        this.data['additional_info']['charterer'] = null
      }

      if (!this.data.cargo) {
        let lastVsoa = await this.axiosGetLastInvoiceByType({ id: item.id, type: 'SHIP' })
        this.data.cargo = lastVsoa?.cargo
      }

      if (!this.data.id) {
        this.data.lay_ports = []
        let lastFreight = await this.axiosGetLastInvoiceByType({ id: item.id, type: 'FREIGHT' })
        this.data.lay_ports = []
        console.log('axiosGetLastInvoiceByType')
        if (Array.isArray(lastFreight.ports) === true) {
          lastFreight.ports.forEach((item) => {
            this.onAddLayPort()
            this.data.lay_ports[this.data.lay_ports.length - 1].loading_port = item.loading_port ? item.loading_port : null
            this.data.lay_ports[this.data.lay_ports.length - 1].discharging_port = item.discharge_port ? item.discharge_port : null
          })
        }

        // if(!this.data.lay_ports[0].loading_port)
        //   this.data.lay_ports[0].loading_port = lastFreight ? lastFreight.loading_port : null
        // if(!this.data.lay_ports[0].discharging_port)
        //   this.data.lay_ports[0].discharging_port = lastFreight ? lastFreight.discharge_port : null
      }
    },
    onValidate() {
      this.getDemurrage()
      this.$v.$touch()
      if (this.$route.params.subId) {
        this.data['additional_info']['charterer'] = this.$route.params.subId
      } else {
        this.data['additional_info']['charterer'] = null
      }
      this.$emit('on-validated', this.$v.$invalid)
    },
  },
  mounted() {
    this.owner = this.activeContract.owner.company_name

    if (this.data && this.data.dispatch_formula) {
      this.activeFormulaDispatch = this.data.dispatch_formula
    }

    if (!this.data.lay_ports.length)
      this.onAddLayPort()

    /* if(this.isLayEdit){
      this.loading_port = this.data.loading_port
      this.discharge_port = this.data.discharge_port

      this.loading_terms = {
        id: this.data.loading_terms,
        name: this.data.loading_terms
      }
      this.discharge_terms = {
        id: this.data.discharge_terms,
        name: this.data.discharge_terms
      }
    } */
    this.data.lay_ports.forEach(item => {
      item['discharging_show'] = this.data.id ? (item.discharging_port ? true : false) : true
      item['loading_show'] = this.data.id ? (item.loading_port ? true : false) : true
    })
    this.data.lay_ports = JSON.parse(JSON.stringify(this.data.lay_ports))
    if (!this.data.additional_info) {
      this.data['additional_info'] = { reversible_between: 'Non reversible' }
    }
    if (!this.data['additional_info']['reversible_between']) {
      this.data['additional_info']['reversible_between'] = 'Non reversible'
    }
  },
  beforeMount() {
    if (this.$route.params.subId) {
      this.selectedDate(this.getCpDates.find(el => el.id == this.$route.params.subId))
    }
  }
}
</script>
<style lang="scss" scoped>
.button-new-port {
  cursor: pointer;
  position: absolute;
  right: 0;
}

.block-two-inputs {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &>* {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: calc(50% - 12px);
  }
}

.block-choose-type {
  width: 100%;

  &>* {
    height: 36px;

    width: 50%;
    box-sizing: border-box;
    border: 1px solid var(--color-border);
    background-color: var(--color-bgc-page);
  }

  &>*:nth-child(1) {
    border-radius: 4px 0 0 4px;
  }

  &>*:nth-child(2) {
    border-radius: 0 4px 4px 0;
  }

  .active {
    color: var(--color-white-always) !important;
    background-color: var(--color-blue-dark);
    border: 1px solid var(--color-blue-dark);
  }
}

.caps-name {

  /deep/ {
    .multiselect__single,
    .multiselect__element {
      span {
        text-transform: uppercase;
      }
    }
  }

}

.dispatch-block {
  width: 145px;
}

.wrap-formula-dispatch {
  margin-top: 14px;
  width: calc(100% - 145px - 12px);
}

.formula-dispath {
  overflow: hidden;
  width: 100%;
  display: flex;
  border: 1px solid var(--color-border);
  height: 32px;
  background-color: var(--color-input);
  border-radius: 4px;

  button {
    font-size: 14px;
    width: 33.3%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .1s linear;
  }

  .active-selected-button {
    background-color: var(--color-blue-dark);
    color: var(--color-white-always)
  }

  &>*:not(:last-child) {
    border-right: 1px solid var(--color-border)
  }
}

.block-two {
  display: flex;
  justify-content: space-between;
}
</style>