import axios from 'axios'

export default {
    actions: {
        axiosRegistration(ctx, data) {
            // // console.log(data);
            if(data.avatar === null) {
                return axios.post(process.env.VUE_APP_URL+"/user", {
                        // "password": "Qwerty1",
                        "email": data.email,
                        "phone_number": data.phone,
                        "first_name": data.first_name,
                        "last_name": data.last_name,
                        "department": data.department,
                        "is_active": true,
                        "password": data.password,
                    }, {
                        headers: {
                            'Authorization': 'token ' + ctx.getters.getToken,
                        }
                    }
                    ).then(response => {
                    let data = response;
                    // // console.log(data);
                    ctx.commit("setResponseRegistration", data);
                }).catch(() => {
                    if(ctx.getters.getToken>20)
                        ctx.commit("setAlert", {show: true, text: "Please check the correctness of the entered data, perhaps such a user already exists."}, {root: true});
                })
            } else {
                // // console.log("avataaaar");
                let formData = new FormData();
                formData.append('password', "Qwerty1");
                formData.append('email', data.email);
                formData.append('phone_number', data.phone);
                formData.append('first_name', data.first_name);
                formData.append('last_name', data.last_name);
                formData.append('department', data.department);
                formData.append('avatar', data.avatar);
                formData.append('is_active', true);


                formData.append('avatar', data.avatar);
                return axios.post(process.env.VUE_APP_URL+"/user", formData,
                    {
                        headers: {
                            'Authorization': 'token ' + ctx.getters.getToken,
                            'Content-Type': 'multipart/form-data',
                        }
                    }).then(response => {
                    let data = response;
                    // console.log(data);
                    ctx.commit("setResponseRegistration", data);
                }).catch(() => {
                    if(ctx.getters.getToken>20)
                        ctx.commit("setAlert", {show: true, text: "Please check the correctness of the entered data, perhaps such a user already exists."}, {root: true});
                })
            }

        },
        axiosChangeUserProfile(ctx, data) {
            console.log("CHANGE")
            console.log(data);
            if(data.avatar === null) {
                return axios.patch(process.env.VUE_APP_URL+"/user/"+ data.id, {
                        "password": "Qwerty1",
                        "email": data.email,
                        "phone_number": data.phone,
                        "first_name": data.first_name,
                        "last_name": data.last_name,
                    }, {
                        headers: {
                            'Authorization': 'token ' + ctx.getters.getToken,
                        }
                    }
                ).then(response => {
                    let data = response;
                    console.log(data);
                    return data.data
                    // ctx.commit("setResponseRegistration", data);
                }).catch(() => {
                    if(ctx.getters.getToken>20)
                        ctx.commit("setAlert", {show: true, text: "Please check the correctness of the entered data, perhaps such a user already exists."}, {root: true});
                })
            } else {
                // // console.log("avataaaar");
                let formData = new FormData();
                formData.append('password', "Qwerty1");
                formData.append('email', data.email);
                formData.append('phone_number', data.phone);
                formData.append('first_name', data.first_name);
                formData.append('last_name', data.last_name);
                formData.append('avatar', data.avatar);

                // formData.append('avatar', data.avatar);
                return axios.patch(process.env.VUE_APP_URL+"/user/" + data.id, formData,
                    {
                        headers: {
                            'Authorization': 'token ' + ctx.getters.getToken,
                            'Content-Type': 'multipart/form-data',
                        }
                    }).then(response => {
                    let data = response;
                    console.log(data);
                    // ctx.commit("setResponseRegistration", data);
                }).catch(() => {
                    if(ctx.getters.getToken>20)
                        ctx.commit("setAlert", {show: true, text: "Please check the correctness of the entered data, perhaps such a user already exists."}, {root: true});
                })
            }
        },
        axiosRelatedPermissions(ctx, data) {
            // // console.log("registration");
            // // console.log(data);
            if(data.related_staff.length !==0 && data.related_department.length !== 0) {
                // // console.log(1);
                return axios.post(process.env.VUE_APP_URL+"/permission/related", {
                    "type": data.type,
                    "user": data.user,
                    "related_staff": data.related_staff,
                    "related_department": data.related_department,
                    related_account: [],
                }, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                        "Content-Type": "application/json",
                        "accept": "application/json",
                    }
                }).then(()=> {
                    ctx.commit("asdasd", data);

                })
            } else if(data.related_staff.length !==0) {
                // // console.log(2);
                return axios.post(process.env.VUE_APP_URL+"/permission/related", {
                    "type": data.type,
                    "user": data.user,
                    "related_staff": data.related_staff,
                }, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                        "Content-Type": "application/json",
                        "accept": "application/json",
                    }
                }).then(()=> {
                    ctx.commit("asdasd", data);

                })
            } else if(data.related_department.length !== 0) {
                // // console.log(3);
                return axios.post(process.env.VUE_APP_URL+"/permission/related", {
                    "type": data.type,
                    "user": data.user,
                    "related_department": data.related_department,
                }, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                        "Content-Type": "application/json",
                        "accept": "application/json",
                    }
                }).then(()=> {
                    ctx.commit("asdasd", data);

                })
            } else {
                // // console.log(4);
                return axios.post(process.env.VUE_APP_URL+"/permission/related", {
                    "type": data.type,
                    "user": data.user,
                }, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                        "Content-Type": "application/json",
                        "accept": "application/json",
                    }
                }).then(()=> {
                    ctx.commit("asdasd", data);

                })
            }

        },
        axiosAddRelatedPermissionForUser(ctx, data) {
          return axios.post(process.env.VUE_APP_URL+"/permission/user/", {
              user: data.user,
              permissions: data.permissions,
          }, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
                  "Content-Type": "application/json",
                  "accept": "application/json",
              }
          })
        },
        axiosChangeDepartmentForUser(ctx, data) {
          return axios.put(process.env.VUE_APP_URL+"/user/" + data.id, {
              "department": data.department,
              email: data.email,
              first_name: data.first_name,
              last_name: data.last_name,
              "password": "Qwerty1",
              phone_number: data.phone_number,
          }, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
                  "Content-Type": "application/json",
                  "accept": "application/json",
              }
          })
        },
        axiosChangeArchiveUser(ctx, data) {
            return axios.put(process.env.VUE_APP_URL+"/user/" + data.id, {
                "department": data.department,
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                "password": "Qwerty1",
                phone_number: data.phone_number,
                is_active: data.is_active,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                    "Content-Type": "application/json",
                    "accept": "application/json",
                }
            })
        },
        axiosChangeRelatedPermissions(ctx, data) {
            if(data.id !== null && data.id !== undefined) {
                if(data.related_staff.length !==0 && data.related_department.length !== 0) {
                    // // console.log(1);
                    return axios.patch(process.env.VUE_APP_URL+"/permission/related/" + data.id, {
                        "type": data.type,
                        "user": data.user,
                        "related_staff": data.related_staff,
                        "related_department": data.related_department,
                    }, {
                        headers: {
                            'Authorization': 'token ' + ctx.getters.getToken,
                            "Content-Type": "application/json",
                            "accept": "application/json",
                        }
                    }).then(()=> {
                        ctx.commit("asdasd", data);

                    })
                } else if(data.related_staff.length !==0) {
                    // // console.log(2);
                    return axios.path(process.env.VUE_APP_URL+"/permission/related/" + data.id, {
                        "type": data.type,
                        "user": data.user,
                        "related_staff": data.related_staff,
                    }, {
                        headers: {
                            'Authorization': 'token ' + ctx.getters.getToken,
                            "Content-Type": "application/json",
                            "accept": "application/json",
                        }
                    }).then(()=> {
                        ctx.commit("asdasd", data);

                    })
                } else if(data.related_department.length !== 0) {
                    // // console.log(3);
                    return axios.path(process.env.VUE_APP_URL+"/permission/related/" + data.id, {
                        "type": data.type,
                        "user": data.user,
                        "related_department": data.related_department,
                    }, {
                        headers: {
                            'Authorization': 'token ' + ctx.getters.getToken,
                            "Content-Type": "application/json",
                            "accept": "application/json",
                        }
                    }).then(()=> {
                        ctx.commit("asdasd", data);

                    })
                } else {
                    // // console.log(4);
                    return axios.path(process.env.VUE_APP_URL+"/permission/related/" + data.id, {
                        "type": data.type,
                        "user": data.user,
                    }, {
                        headers: {
                            'Authorization': 'token ' + ctx.getters.getToken,
                            "Content-Type": "application/json",
                            "accept": "application/json",
                        }
                    }).then(()=> {
                        ctx.commit("asdasd", data);

                    })
                }
            }
        },
        getAxiosIdPermissionRelated(ctx, data) {
            return axios.get(process.env.VUE_APP_URL+"/permission/related/?type="+ data.type + "&user=" + data.user, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                if(response.data.results.length === 0) {
                    ctx.commit("setIdPermissionRelated", null);
                } else {
                    ctx.commit("setIdPermissionRelated", response.data.results[0].id);
                }
            });
        },
        axiosDeletePermissionRelated(ctx, id) {
            return axios.delete(process.env.VUE_APP_URL+"/permission/related/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        }

    },
    mutations: {
        setModalCreateUser(state, data) {
            state.modalCreateUser.status = data.status;
            state. modalCreateUser.name = data.name;
        },
        setDataForAxiosRegistration(state, data) {
            state.dataRegistrationAxios = data;
            localStorage.setItem('dataAddEmployee', JSON.stringify(data))
        },
        setResponseRegistration(state, data) {
            state.responseRegistration = data;
        },
        setIdPermissionRelated(state, data) {
            state.idPermissionRelated = data;
        }
    },
    state: {
        dataRegistrationAxios: null,
        responseRegistration: {},
        modalCreateUser: {
            status: false,
            name: "",
        },
        idPermissionRelated: null,
    },
    getters: {
        getDataForAxiosRegistration(state) {
            if(state.dataRegistrationAxios === null && localStorage.getItem('dataAddEmployee') !== null && localStorage.getItem('dataAddEmployee') !== undefined && (''+localStorage.getItem('dataAddEmployee')).length !== 0) {
                state.dataRegistrationAxios = JSON.parse(localStorage.getItem('dataAddEmployee'))
            }
            return state.dataRegistrationAxios;
        },
        getResponseRegistration(state) {
            return state.responseRegistration;
        },
        getModalCreateUser(state) {
            return state.modalCreateUser;
        },
        getIdPermissionRelated(state) {
          return state.idPermissionRelated;
        },
    },
}
