<template>
  <div class="wrap">
    <div class="part1">
      <!-- <div class="navigation-crumbs">
        <router-link class="fz12 text-gray" to="/employees">Employees</router-link>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.67917 6.38418L4.77259 9.34168C4.5589 9.55313 4.21292 9.55278 3.99958 9.34059C3.78641 9.12843 3.78696 8.78475 4.00068 8.57314L6.51903 5.99997L4.00046 3.42684C3.78676 3.2152 3.78621 2.87174 3.99936 2.65955C4.1063 2.55318 4.24641 2.5 4.38651 2.5C4.52626 2.5 4.66581 2.55283 4.77256 2.65846L7.67917 5.6158C7.78209 5.71746 7.83984 5.85582 7.83984 5.99997C7.83984 6.14413 7.78192 6.28233 7.67917 6.38418Z" fill="#9AA3B0"/>
        </svg>
        <p class="fz12 text-gray">{{getInfoForUser.first_name}} {{getInfoForUser.last_name}}</p>
      </div> -->
      <bread 
        :allowOptions="false" 
        :allowBorder="false" 
        :links="[
          {name: 'Employees', link: '/employees'}, 
          {name: `${getInfoForUser.first_name} ${getInfoForUser.last_name}`, link: ''}
        ]"
      />
      <div class="avatar">
        <svg class="no-avatar" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="getInfoForUser.avatar === null || getInfoForUser.avatar === undefined">
          <circle cx="80" cy="80" r="80" fill="white"/>
          <path d="M80 0.00308228C35.8218 0.00308228 0 35.8179 0 79.9996C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9996C160 35.8179 124.185 0.00308228 80 0.00308228ZM80 23.923C94.6183 23.923 106.464 35.7722 106.464 50.3835C106.464 64.9983 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9983 53.543 50.3835C53.543 35.7722 65.3887 23.923 80 23.923ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
        </svg>
        <img v-if="getInfoForUser.avatar !== null && getInfoForUser.avatar !== undefined" :src="getInfoForUser.avatar" alt="">
      </div>
      <p class="name-user fw500 text-dark fz20">
        {{getInfoForUser.first_name}}
      </p>
      <p class="surname-user fw500 text-dark fz20">
        {{getInfoForUser.last_name}}
      </p>
        <p class="fz12 title text-gray">Contact Number</p>
        <p class="phone fz14 text-dark">{{getInfoForUser.phone_number}}</p>
      <p class="fz12 title text-gray">Email</p>
      <p class="phone fz14 text-dark">{{getInfoForUser.email}}</p>
      <router-link :to="'change-profile/' + getInfoForUser.id" class="edit-user">
        <svg width="24" class="svg-dart" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.1088 5.89833C17.5331 5.31995 16.7695 5 15.9528 5C15.1403 5 14.3726 5.31995 13.7969 5.89833L5.74055 13.9915C5.65071 14.0817 5.59763 14.1966 5.57721 14.3197L5.00555 18.3437C4.98105 18.5201 5.03822 18.7006 5.1648 18.8277C5.27097 18.9385 5.42205 19 5.57313 19C5.59763 19 5.61805 19 5.64255 18.9959L9.71358 18.4873C9.84017 18.4708 9.95858 18.4134 10.0484 18.3232L18.1088 10.2259C19.2971 9.02813 19.2971 7.092 18.1088 5.89833ZM9.37875 17.3674L6.24279 17.7571L6.68379 14.6683L13.5764 7.74421L16.2673 10.4474L9.37875 17.3674ZM17.3044 9.41781L17.0798 9.64342L14.3889 6.94023L14.6135 6.71462C14.9688 6.34544 15.4465 6.14855 15.9569 6.14855C16.4633 6.14855 16.9451 6.34544 17.3044 6.71052C17.6637 7.07559 17.8638 7.55142 17.8638 8.06417C17.8638 8.57281 17.6678 9.05684 17.3044 9.41781Z" fill="#094172"/>
        </svg>
      </router-link>
      <div class="department-rights">
        <div>
          <p class="fz12 text-gray">Department and permissions</p>
          <p class="phone fz14 text-dark">{{ getDepartmentName() }}</p>
        </div>

        <router-link :to="'change-rights/' + getInfoForUser.id" class="edit-right">
          <svg class="svg-dart" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1088 5.89833C17.5331 5.31995 16.7695 5 15.9528 5C15.1403 5 14.3726 5.31995 13.7969 5.89833L5.74055 13.9915C5.65071 14.0817 5.59763 14.1966 5.57721 14.3197L5.00555 18.3437C4.98105 18.5201 5.03822 18.7006 5.1648 18.8277C5.27097 18.9385 5.42205 19 5.57313 19C5.59763 19 5.61805 19 5.64255 18.9959L9.71358 18.4873C9.84017 18.4708 9.95858 18.4134 10.0484 18.3232L18.1088 10.2259C19.2971 9.02813 19.2971 7.092 18.1088 5.89833ZM9.37875 17.3674L6.24279 17.7571L6.68379 14.6683L13.5764 7.74421L16.2673 10.4474L9.37875 17.3674ZM17.3044 9.41781L17.0798 9.64342L14.3889 6.94023L14.6135 6.71462C14.9688 6.34544 15.4465 6.14855 15.9569 6.14855C16.4633 6.14855 16.9451 6.34544 17.3044 6.71052C17.6637 7.07559 17.8638 7.55142 17.8638 8.06417C17.8638 8.57281 17.6678 9.05684 17.3044 9.41781Z" fill="#094172"/>
          </svg>
        </router-link>
      </div>
      <div class="archive-profile">
        <p class="phone fz14 text-dark">Archive profile</p>
        <button class="archive-button" v-on:click="showModalArchive = true">
          <svg class="svg-dart" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="getInfoForUser.is_active">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
          </svg>
          <svg class="svg-dart" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!getInfoForUser.is_active">
            <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
            <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#9AA3B0"/>
          </svg>
        </button>


      </div>
      <div class="reset-password mt12">
        <button class="blue-button-text fz16" v-on:click="showModalChangePassword = true">Change password</button>
      </div>
<!--{{getInfoForUser}}-->
    </div>
    <div class="part2">
<!--    {{getDepartments}}-->
    </div>
    <transition name="fade">
      <div class="modal-archive-wrap" v-if="showModalArchive">
        <div class="filter-modal" v-on:click="showModalArchive = false"></div>
        <div class="modal-archive">
          <div class="top-block">
            <p class="fw500 fz16 text-dark">Archive the employee</p>
            <button class="button-close-modal" v-on:click="showModalArchive = false">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
                <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <p class="fz14 text-dark">Are you sure you want to archive this employee?</p>
          <div class="block-buttons">
            <button class="blue-button-text fz14" v-on:click="showModalArchive = false">Cancel</button>
            <button class="blue-button fz14 fw500 buttonW100 h32" v-on:click="archiveOrUnArchiveUser">Archive</button>
          </div>
        </div>
      </div>
    </transition>
    <ModalChangePassword @close="showModalChangePassword = false" :showModal="showModalChangePassword" :id="getInfoForUser.id"></ModalChangePassword>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import bread from '@/components/reuse/bread';
import ModalChangePassword from "../../components/modal-change-password/modal-change-password.vue";

export default {
  components: { bread, ModalChangePassword },
  computed: mapGetters(["getDepartments","getInfoForUser", "getLoginData"]),
  name: "employeeCard",
  data() {
    return {
      showModalArchive: false,
      showModalChangePassword: false,
    }
  },
  methods: {
    ...mapActions(["axiosChangeArchiveUser", "axiosGetDepartments","getAxiosInfoForUser"]),
    ...mapMutations(['setUserDataUSER', 'showLoad', 'hideLoad']),
    getDepartmentName() {
      for(let i=0; this.getDepartments.data.results.length; i++) {
        if(this.getDepartments.data.results[i].id === this.getInfoForUser.department) {
          return this.getDepartments.data.results[i].name;
        }
      }
      return null;
    },
    archiveOrUnArchiveUser() {
      this.showModalArchive = false;
      this.axiosChangeArchiveUser({id: this.getInfoForUser.id, department: this.getInfoForUser.department, email: this.getInfoForUser.email, first_name: this.getInfoForUser.first_name, last_name: this.getInfoForUser.last_name, phone_number: this.getInfoForUser.phone_number, is_active: !this.getInfoForUser.is_active}).then(()=> {
        // this.refreshData
        this.$router.push('/employees')
      });
    },
    refreshData() {
      let textRoute = this.$route.path;
      let idTask = textRoute.replace(/[^0-9]/gim,'');
      this.showLoad()
      this.getAxiosInfoForUser(idTask).then(()=> {
        this.hideLoad()
      })
      this.axiosGetDepartments();
    },
  },
  mounted() {
    this.refreshData();
  },
  watch: {
    $route: function () {
      this.refreshData();
    }
  }
}
</script>

<style scoped lang="scss">
@import "employeeCard";
.dart-mode {
  .svg-dart {
    path {
      fill: #FFFFFF;
    }
    &:hover {
      path {
        fill: var(--color-blue)
      }
    }
  }
}
</style>